import React from 'react'
import './SASection.css'
import Menu1 from '../menus/Menu1';
import Menu2 from '../menus/Menu2';




const MHSection = () => {
  return (
    <>
<Menu1 />
<Menu2 />


    </>
  )
}

export default MHSection
