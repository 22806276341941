import React from 'react'
import './MHSection.css'
import Menu1 from '../../components/menus/Menu1';
import Menu3 from '../../components/menus/Menu3';




const MHSection = () => {
  return (
    <>

<Menu1 />
<Menu3 />

    </>

  )
}

export default MHSection
